import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Libra.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Libra Nature
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/libra"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Libra</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Libra Nature</h4>
              
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Libra Nature </h1>
                <h2 className="text-sm md:text-base ml-4">Sep 23 - Oct 23</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-love");
                  }}>
                  <h5 className="font-semibold text-black">Libra Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-nature");
                  }}>
                  <h5 className="font-semibold text-black">Libra Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-man");
                  }}>
                  <h5 className="font-semibold text-black">Libra Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Libra Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-traits");
                  }}>
                  <h5 className="font-semibold text-black">Libra Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-facts");
                  }}>
                  <h5 className="font-semibold text-black">Libra Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
             <p className="playfair text-black text-xl md:text-3xl">Libra Nature</p><br/>
             Libra & natives are known for their excellent taste, harmony, equality, elegance, and charm. You are more inclined to gentleness, balance, and beauty. So you want to be honest in all your transactions and activities. You carefully examine and investigate the pros and cons before concluding. You are friendly, socially active, and romantic by nature. You can make friends easily and are prevalent in the social circle. However, you hate being alone. Above this, you earn the respect of your friends and people in your process with your quick grasping nature and sharp intellect. <br></br>
             <br></br>
             Librans focus strongly on partnership and are sensitive to the needs of others. You are gifted with a naturalistic sense of knowing the difference between right and wrong. Hence, you are very attentive to justice, honesty, and fairness. You concentrate on logic and realities and make sure things are from all angles. You simply like to weigh the best options for yourself and those around you. So you always ensure that all the parties involved will be beneficial from your decisions before finalizing them. <br></br>
             <br></br>
             The energy of the Libra sign makes the natives more charming, lovable, fair, sincere, sharing, beautiful, and hopelessly romantic. Conversely, you can be arrogant, uncertain, dramatic, manipulative, rotten, and delusional. People get easily attracted to you as your beauty and style are the centers of attraction for many. You hate to see others be unhappy around you due to unequal circumstances. You always seek unity around you and hence are incredibly diplomatic and fair. Will your diplomacy and fairness help you to succeed in the year 2024? Here you get your free personalized 2024 yearly report. You put more effort into maintaining equilibrium and feel happy and satisfied after finding and implementing balance. <br></br>
            <br></br>
            <p className="font-bold text-orange-500 inline-block mr-2">Libra Ruling Planet:</p>Venus
            Venus, the planet of love, casts seductive powers of persuasiveness and charisma on you. The vitality of Venus adds joy and magnification to everything it feels, from food to style to your personality. The planet imbibes elegance, charm, and looks in you. The sparkling love qualities of Venus radiate deeply in your life. Also, the planet's energy favorably makes you committed, docile, sensitive, and possessive. <br></br>
            <br></br>
            <p className="font-bold text-orange-500 inline-block mr-2">Ruling House:</p>Seventh (Relationships)
            The 7th house impacts our one-on-one relationships. It usually influences our romantic relationships. But this can also guide other operating relationships. Your honest, elegant, understanding, and empathetic power creates a peaceful balance in your intimate life. You also can value and understand your partner's requirements, emotions, and desires. Investigate more about your relationships in your personal and professional life during the year 2024. <br></br>
            <br></br>
            <p className="font-bold text-orange-500 inline-block mr-2">Habits</p>
            You have the habit of spending money willingly and lavishly. Librans love to have the finest of everything. So you will spend all your money to buy the luxurious items you want. You continuously want to be an influencer in your circle. You find the problem in saying no. However, you like to please others, but this may lead to an overcommitted program. Also, you have a habit of slipping in love too quickly. You will feel exhausted due to your training of going out of your path to avoid disputes. <br></br>
            <br></br>
            <p className="font-bold text-orange-500 inline-block mr-2">Element:</p>Air
            Libra is the second air sign, and the factor will lead you with elegant gust, pleasant manners, and colonial charm. You usually obtain shift mildly and moderately than others hardly even witness that they have been swept someplace new. The significance of the air element makes you communicative and intelligent and helps you distribute concepts. You encourage settlement, helpful discussion, and victorious connections. <br></br>  
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Strengths</p><br/>
            You have a healthy sense of what is right and what is wrong. Typically, you are profoundly concerned about justice and fairness for the individuals involved in a specific situation, as you do not like noticing anyone treated brashly or unfairly. You are a fair arbitrator and crack problems between other individuals. Therefore, you are modeled after the hierarchies of fairness and usually make a reasonable judgment. Nevertheless, you are more prone to maintaining stability, peace, balance, and harmony near you. <br></br> 
            <br></br>
             To evade the battle, you will choose to be self-sacrificing as the more significant good of the individuals about you is more meaningful to you. You even give up multiple things for your family or friends. You find pleasure in seeing the smile on others' faces. You come up adorable, accommodating, and helpful to the people you love transmitting your possessions and knowledge with. You are an excellent chatterbox and sociable. You may even love to chitchat with others. So you have many faithful and trustworthy friends. Usually, you come up with a carefully weighed response to a query or some well-thought-out guidance because of your objectiveness and a deep understanding of ethics and values. <br></br>
             <br></br>
             <p className="playfair text-black text-xl md:text-3xl">Weaknesses</p><br/>
             It is challenging to trust your words because of your diplomacy and prudence. Whatever you speak is not likely straightforward and robust as you carefully select your comments before confronting anything. Simply think twice before you speak. Usually, you don't share or disclose your genuine feelings. Particularly when you feel that your acknowledgment can construct debates or hot talks. You have an extreme dislike of conflict. Hence, you bypass confrontation most of the time. However, those adverse feelings will regularly bottle up gradually. This can lead to passive aggression or demolish everything one good day after the most minor violation. <br></br>
             <br></br>
             Check your daily horoscope, weekly horoscope, monthly horoscope, and yearly horoscope absolutely free..!!! <br></br>
             <br></br>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
